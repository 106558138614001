import AlephBet from "alephbet"
import {storage_adapter, lamed_adapter} from "./adapters"
import {not_logged_in, on_pt_page, on_registration_page} from "./helpers"
import {registered_successfully} from "./goals"
import $ from "cash-dom"
import pt_img from "images/testimonial_pt.webp"

export default () => {
  // eslint-disable-next-line max-len
  const localized_testimonial_on_registration_page_pt_v3 = new AlephBet.Experiment({
    name: "202403 Localized testimonial on registration page PT (3)",
    tracking_adapter: lamed_adapter,
    storage_adapter: storage_adapter,
    trigger: () => (not_logged_in() && on_pt_page() && on_registration_page()),
    variants: {
      "1-Original": {
        activate: () => {
        }
      },
      "2-PT-variant": {
        activate: (experiment) => {
          if (!on_pt_page()) return
          // eslint-disable-next-line max-len
          const name = "Mariana Freire, Estudante de medicina <br/>Universidade Federal de Juiz de Fora"
          const img = pt_img
          $(".localized-info").html(name)
          $(".localized-img").attr("src", img)
          $(".localized-img").attr("alt", name)
        }
      }
    }
  })

  localized_testimonial_on_registration_page_pt_v3.add_goals([
    registered_successfully
  ])
}
