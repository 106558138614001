import $ from "cash-dom"
import timeouts from "./timeouts"

// https://github.com/fabiospampinato/cash/issues/361
export const is_visible = (elem) =>
  !!(elem &&
    (elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length))

export const fade_out = (elem, duration = 400, remove = true) => {
  const $elem = $(elem)
  $elem.css("transition", `opacity ${duration}ms`).css("opacity", 0)
  if (remove) setTimeout(() => $elem.remove(), duration)
}

export const fade_in = (elem, duration = 400) => {
  const $elem = $(elem)
  $elem.css("transition", `opacity ${duration}ms`).css("opacity", 1)
}

export const slide_in = (el, border, duration = 400) => {
  const $el = $(el)
  const value = ["top", "bottom"].includes(border)
    ? $el.outerHeight()
    : $el.outerWidth()
  $el
    .css(border, -value)
    .css("opacity", 1)
  // have to user this timeout here to make it work in knockout
  setTimeout(() => {
    $el
      .css("transition", `${border} ${duration}ms`)
      .css(border, 0)
  }, 10)
  return new Promise(resolve => timeouts.set(resolve, duration))
}

export const slide_out = (el, border, duration = 400) => {
  const $el = $(el)
  const value = ["top", "bottom"].includes(border)
    ? $el.outerHeight()
    : $el.outerWidth()
  $el
    .css("transition", `${border} ${duration}ms`)
    .css(border, `-${value}px`)
  const promise = new Promise(resolve => timeouts.set(resolve, duration))
  return promise.then(() => $el.css("opacity", 0))
}

export const get_or_append = (id) => {
  let container = document.getElementById(id)
  if (container) return container
  container = document.createElement("div")
  container.setAttribute("id", id)
  document.body.appendChild(container)
  return container
}

export const update_icon = (svg, name) => {
  svg.firstElementChild.setAttribute("href", `${window.gon.icons_path}#${name}`)
}
