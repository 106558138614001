import Basil from "basil.js"
const store = new Basil({
  namespace: "",
  storages: ["local", "cookie", "session", "memory"]
})

const storage = {enabled: true}

const wrap = (fn, error_handler) =>
  function() {
    try {
      return fn.apply(this, arguments)
    } catch (e) {
      return error_handler(e)
    }
  }

const handle_error = () => {
  if (!storage.enabled) return
  return (storage.enabled = false)
}

storage.set = wrap(store.set, handle_error).bind(store)
storage.get = wrap(store.get, handle_error).bind(store)
storage.remove = wrap(store.remove, handle_error).bind(store)
storage.clear = wrap(store.reset, handle_error).bind(store)

export default storage
