const rollbar_helper = {
  set_error_payload: (payload) => {
    if (!payload) return
    return window.Rollbar.configure({payload})
  },

  get_error_payload: () => window.Rollbar.options.payload,

  send_error_msg: (msg, payload) => {
    const method = payload?.level ? payload.level : "error"
    return window.Rollbar[method](msg, payload)
  }
}

export default rollbar_helper
