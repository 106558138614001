import supported_browsers from "supported_browsers"
import Rollbar from "rollbar"

const _rollbarConfig = {
  accessToken: window.gon.rollbar_access_token,
  captureUncaught: true,
  captureUnhandledRejections: true,
  hostSafeList: [
    // only tracks errors from our assets to reduce noise from browser plugins
    "kenhub.com/assets",
    "stripe.com",
    "typeform.com",
    "helpscout.com",
    "google.com",
    "amplitude.com",
    "headwayapp.co"
  ],
  checkIgnore: (_isUncaught, args, _payload) =>
    !supported_browsers.test(window.navigator.userAgent) ||
      [
        "Load failed",
        "A network error occurred.",
        "The user aborted a request.",
        "The operation was aborted."
      ].includes(args && args[0].trim && args[0].trim()),
  enabled: !window.gon.disable_rollbar_js,
  verbose: window.gon.rails_env !== "production",
  payload: {
    environment: window.gon.rails_env,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: window.gon.release,
        guess_uncaught_frames: true
      }
    }
  }
}

if (window.gon.current_user) {
  _rollbarConfig.payload.person = {
    id: window.gon.current_user.user_id,
    email: window.gon.current_user.email,
    color_blindness: window.gon.color_blindness
  }
}

window.Rollbar = new Rollbar(_rollbarConfig)
