let timeouts = []

export default {
  set: (...args) => {
    const timeout = setTimeout(...args)
    timeouts.push(timeout)
    return timeout
  },

  remove_all: () => {
    for (const timeout of timeouts) clearTimeout(timeout)
    return (timeouts = [])
  }
}
