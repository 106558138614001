// we can't use URLSearchParams() because it would convert test = [1,2]
// to test="1,2" but Rails expects test[]=1&test[]=2
// loosly based on https://github.com/jquery/jquery/blob/a684e6ba836f7c553968d7d026ed7941e1a612d8/src/serialize.js
const serialize_params = (data) => {
  if (!data) return ""

  return Object.keys(data).map((key) => serialize(key, data[key])).join("&")
}

const serialize = (key, value) => {
  if (Array.isArray(value)) {
    return value.map((v) => serialize(`${key}[]`, v)).join("&")
  } else if (value instanceof Object) {
    return Object.keys(value).map((value_key) =>
      serialize(`${key}[${value_key}]`, value[value_key])
    ).join("&")
  }
  if (value == null) value = ""
  return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
}

export default serialize_params
