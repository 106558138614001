import storage from "storage"

export default class StorageManager {
  constructor(namespace) {
    this.namespace = namespace
    if (!this.namespace) throw new Error("StorageManager needs a namespace")
    this.properties = this._load_data()
  }

  _load_data() {
    let data
    if (storage.enabled) data = storage.get(this.namespace)
    if (!data) data = {}
    if (typeof data !== "object") return {}
    return data
  }

  _save() {
    return storage.set(this.namespace, this.properties)
  }

  _check_for_object(dict) {
    if (typeof dict === "object") return
    throw new TypeError("Parameter is not an object", dict)
  }

  get(key) {
    this.properties = this._load_data()
    return this.properties[key]
  }

  get_all() {
    this.properties = this._load_data()
    return {...this.properties}
  }

  register(dict) {
    this._check_for_object(dict)
    this.properties = this._load_data()
    Object.assign(this.properties, dict)
    this._save()
    return this.get_all()
  }

  register_once(dict) {
    this._check_for_object(dict)
    this.properties = this._load_data()
    for (const property in dict) {
      if (this.properties[property] === undefined) {
        this.properties[property] = dict[property]
      }
    }
    this._save()
    return this.get_all()
  }

  remove(key) {
    this.properties = this._load_data()
    const value = this.properties[key]
    if (!value) return
    delete this.properties[key]
    this._save()
    return value
  }
}
