import {experiment_tracking} from "./helpers"
import {LamedAdapter} from "alephbet"
import storage from "storage"

class StorageAdapter {
  constructor() {
    this.namespace = "alephbet"
    this.storage = storage.get(this.namespace) || {}
  }

  set(key, value) {
    this.storage[key] = value
    storage.set(this.namespace, this.storage)
    return value
  }

  get(key) {
    return this.storage[key]
  }
}

class LamedTrackingAdapter {
  experiment_start(experiment, variant) {
    experiment_tracking(experiment.name, variant)
    lamed_adapter.experiment_start(experiment, variant)
  }

  goal_complete(experiment, variant, goal_name, props) {
    lamed_adapter.goal_complete(experiment, variant, goal_name, props)
  }
}

export const storage_adapter = new StorageAdapter()
export const super_properties_lamed_adapter = new LamedTrackingAdapter()

export const lamed_adapter = new LamedAdapter(
  window.gon.lamed_track_url,
  window.gon.lamed_namespace,
  storage_adapter
)
