import EventEmitter from "events"

if (!window.kenhub_event_emitter) {
  window.kenhub_event_emitter = new EventEmitter()
}

const history = []

const signals = {
  emit: (name, props) => {
    history.push({name, props})
    window.kenhub_event_emitter.emit(name, props)
  },

  on: (event_name, handler) =>
    window.kenhub_event_emitter.on(event_name, handler),

  history: history,

  remove_all: () => window.kenhub_event_emitter.removeAllListeners()
}

export default signals
