import rollbar_helper from "rollbar_helper"
import refresh_authenticity_token, {
  get_csrf_token_from_doc, reset_promise
} from "./refresh_authenticity_token"
import serialize_params from "serialize_params"

const ajax = {
  post: (url, data) => fetch_with_body(url, "POST", data),
  put: (url, data) => fetch_with_body(url, "PUT", data),
  delete: (url) => fetch_with_body(url, "DELETE"),
  get: (url, data) => {
    if (data) url = `${url}?${serialize_params(data)}`
    return fetch(url, {headers: {"Content-Type": "application/json"}})
      .catch((error) => handle_connection_failure(error, url, {method: "GET"}))
      .then((response) => handle_response(response, {method: "GET"}))
  },
  get_turbo_stream: (url) =>
    fetch(url, {headers: {Accept: "text/vnd.turbo-stream.html"}})
      .catch((error) => handle_connection_failure(error, url, {method: "GET"}))
      .then(response => response.text())
}

async function fetch_with_body(url, method, data) {
  const body = data && JSON.stringify(data)
  const headers = await ajax_headers()
  const options = {method, headers, body}
  const response = await fetch_with_headers(url, options)
  return handle_response(response, options)
}

async function fetch_with_headers(url, options) {
  try {
    return await fetch(url, options)
  } catch (error) {
    return handle_connection_failure(error, url, options)
  }
}

export async function ajax_headers() {
  const csrf_token = await refresh_authenticity_token()
  return {
    "X-CSRF-Token": csrf_token,
    "Content-Type": "application/json"
  }
}

// inspired by https://gist.github.com/odewahn/5a5eeb23279eed6a80d7798fdb47fe91
// Raise an exception to reject the promise and trigger the outer .catch() handler.
// By default, an error response status (4xx, 5xx) does NOT cause the promise to reject!
function handle_response(response, options) {
  return response.text().then((data) => {
    try {
      data = data && JSON.parse(data)
    } catch {
      const error = response.ok
        ? TypeError("fetch did not return a valid JSON response")
        : Error(`Ajax ${options.method} error (${response.status})`)
      track_error(error, response, data, options)
      // removes cached promise for CSRF token if the server responds with 422
      if (response.status === 422) reset_promise()
      return Promise.reject(error)
    }
    if (response.ok) return data
    return Promise.reject(data)
  })
}

function handle_connection_failure(error, url, options = {}) {
  rollbar_helper.send_error_msg(`Ajax ${options.method} failure`, {
    level: "warn",
    ajax_url: url,
    error: error,
    ...options
  })
  return Promise.reject(error)
}

function track_error(error, response, data, options) {
  if (!response.status) return

  rollbar_helper.send_error_msg(error.toString(), {
    status: response.status,
    ajax_url: response.url,
    level: "warn",
    response_data: data,
    csrf_token_from_doc: get_csrf_token_from_doc(),
    ...options
  })
}

export default ajax
