import route_regex from "route_regex"

const page_load = {
  on_ready: (callback) => {
    // if Turbo is loaded yet (essential.js file)
    if (!window.Turbo) {
      if (document.readyState === "loading") {
        window.addEventListener("DOMContentLoaded", callback)
      } else {
        setTimeout(callback)
      }
      // register callback for the turbo:load event but only after the load
      // event to prevent that the callback is called twice on inital page load
      window.addEventListener("load", () => page_load.on_turbo_load(callback))
    } else {
      page_load.on_turbo_load(callback)
      // if the load event has already occurred
      if (document.readyState === "complete") {
        // call callback immeditaly since turbo won't call it anymore
        setTimeout(callback)
      }
    }
  },

  on_turbo_load: (callback) => {
    window.addEventListener("turbo:load", event => {
      // workaround for redirects to only call the callback once although this
      // event gets triggered twice
      // it also ignores the hash param since the server url doesn't include it
      if (event.detail.url.split("#")[0] === window.gon.current_url) callback()
    })
  },

  on_click: (callback) =>
    document.addEventListener("turbo:click", callback),

  prevent_turbo: (url) => {
    if (!url) return false
    if (url.match(/contact_modal/)) return true
    if (url.match(/contact_us/)) return true
    const excluded_urls = [
      new RegExp(`^/${window.gon.locale}/login$`),
      new RegExp(`^/${window.gon.locale}/register$`),
      new RegExp(`^/${window.gon.locale}/logout$`),
      new RegExp(`^/${window.gon.locale}/trainer`),
      new RegExp(`^/${window.gon.locale}/profile/setup_mission_complete$`),
      new RegExp(`^/${window.gon.locale}/profile/setup$`),
      route_regex("?/?$"),
      /^\/(login|registration)\/google$/,
      /^\/admin/
    ]
    const path = (new URL(url, window.location)).pathname
    return excluded_urls.some((matcher) => matcher.test(path))
  }
}

page_load.on_click((event) => {
  if (page_load.prevent_turbo(event?.detail?.url)) {
    return event.preventDefault()
  }
})

export default page_load
