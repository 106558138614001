import AlephBet from "alephbet"
import {storage_adapter, lamed_adapter} from "./adapters"
import {not_logged_in, on_article_page} from "./helpers"
import {registered_successfully, inline_quiz_finished} from "./goals"

export default () => {
  const sample_quiz_on_articles_v3 = new AlephBet.Experiment({
    name: "202411 Sample quiz on articles v3",
    tracking_adapter: lamed_adapter,
    storage_adapter: storage_adapter,
    trigger: () => not_logged_in() && on_article_page(),
    variants: {
      "1-Original": {
        activate: () => {
        }
      },
      "2-Sample-quiz": {
        activate: (experiment) => {
          if (!experiment.options.trigger()) return

          window.gon.sample_quiz_v3 = true
        }
      }
    }
  })

  sample_quiz_on_articles_v3.add_goals([
    registered_successfully,
    inline_quiz_finished
  ])
}
