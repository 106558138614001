// for GI and testing in the browser console
import "rollbar_loader"
import "experiments/init"
import $ from "cash-dom"
import page_load from "page_load"
import load_media from "load_media"

import "stylesheets/site/essential.scss"

const load_init = () => {
  load_application_css()
  load_fonts().then(set_css_variables)
  page_load.on_ready(load_application_css)
  return import(/* webpackChunkName: "init" */ "init").catch(e => {
    $("#offline_messages .notice").show()
    // eslint-disable-next-line no-console
    console.error(e)
  })
}

const load_application_css = () => {
  load_media(document.getElementById("deferred_styles"))
}

const load_fonts = () => {
  if (!("fonts" in document)) return Promise.resolve()

  return Promise.all([
    document.fonts.load("400 12px PlutoSansLight"),
    document.fonts.load("400 12px PlutoSansMedium"),
    document.fonts.load("400 12px PlutoSansRegular"),
    document.fonts.load("400 12px PlutoLightItalic")
  ])
}

const set_css_variables = () => {
  document.documentElement.style
    .setProperty("--light", "PlutoSansLight, Helvetica, Arial, sans-serif")
  document.documentElement.style
    .setProperty("--medium", "PlutoSansMedium, Helvetica, Arial, sans-serif")
  document.documentElement.style
    .setProperty("--regular", "PlutoSansRegular, Helvetica, Arial, sans-serif")
  document.documentElement.style
    .setProperty("--light-italic", "PlutoLightItalic, Helvetica, Arial, sans-serif")
}

window.addEventListener("load", load_init)

// prevent the pwa install prompt for visitors
// https://web.dev/customize-install/
if (!window.gon.current_user) {
  window.addEventListener("beforeinstallprompt", (e) => e.preventDefault())
}

// displays the page after the experiment code was executed
// has to be executed one execution cycle later because otherwise the
// experiments would still flicker
page_load.on_ready(() => {
  document.documentElement.classList.remove("async-hide")
})

// makes them accessible from the outside
export {default as jquery} from "cash-dom"
export {default as storage} from "storage"
export * as dom_utils from "dom_utils"
