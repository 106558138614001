import AlephBet from "alephbet"
import {storage_adapter, lamed_adapter} from "./adapters"
import {on_checkout_page} from "./helpers"
import {premium_subscription_started} from "./goals"
import $ from "cash-dom"

export default () => {
  const visual_checkout_page = new AlephBet.Experiment({
    name: "202408 Visual checkout page",
    tracking_adapter: lamed_adapter,
    storage_adapter: storage_adapter,
    trigger: () => (on_checkout_page()),
    variants: {
      "1-Original": {
        activate: () => {
        }
      },
      "2-Visual-checkout": {
        activate: (experiment) => {
          if (!experiment.options.trigger()) return

          let item_clicked = false

          function cycleBenefits() {
            if (item_clicked) {
              return
            }
            let active_item = $(".benefit.active")
            active_item.removeClass("active")
            active_item = active_item.next()
            if (active_item.length === 0) {
              active_item = $(".benefit").first()
            }
            active_item.addClass("active")
          }

          setInterval(() => {
            cycleBenefits()
          }, 5000)

          $(".benefit").on("click", (e) => {
            $(".benefit.active").removeClass("active")
            e.target.classList.add("active")
            item_clicked = true
          })

          $("body").addClass("ab_test-229-visual-checkout-page")
        }
      }
    }
  })

  visual_checkout_page.add_goals([
    premium_subscription_started
  ])
}
