import ajax from "./ajax"

let promise = null

function refresh_authenticity_token() {
  if (promise) return promise

  promise = ajax.get("/authenticity_token")
    .then(update_authenticity_token)
    .catch(() => {
      promise = null
    })
  return promise
}

// refreshing the meta headers + all form inputs
// (also see https://github.com/rails/jquery-ujs)
function update_authenticity_token({token}) {
  const meta = document.querySelector("meta[name='csrf-token']")
  meta?.setAttribute("content", token)
  const inputs =
    document.querySelectorAll("form input[name='authenticity_token']")
  for (const input of inputs) {
    input.setAttribute("value", token)
  }
  return token
}

export function get_csrf_token_from_doc() {
  const meta = document.querySelector("meta[name='csrf-token']")
  return meta?.content
}

// used be the tests to reset the promise
export function reset_promise() {
  promise = null
}

export default refresh_authenticity_token
