import AlephBet from "alephbet"
import {storage_adapter, lamed_adapter} from "./adapters"
import {not_logged_in, on_registration_page} from "./helpers"
import {registered_successfully} from "./goals"
import $ from "cash-dom"

export default () => {
  const highlight_registration_benefits = new AlephBet.Experiment({
    name: "202411 Highlight registration benefits",
    tracking_adapter: lamed_adapter,
    storage_adapter: storage_adapter,
    trigger: () => not_logged_in() && on_registration_page(),
    variants: {
      "1-Original": {
        activate: () => {
        }
      },
      "2-New-benefits": {
        activate: (experiment) => {
          if (!experiment.options.trigger()) return

          $(".bullet-list.ab_test--original").remove()
          $(".bullet-list.ab_test--variant").addClass("active")
        }
      }
    }
  })

  highlight_registration_benefits.add_goals([
    registered_successfully
  ])
}
