import user_settings from "user_settings"
import {logged_in_user, on_dashboard} from "./helpers"
import route_regex from "route_regex"
import AlephBet from "alephbet"
import signals from "signals"

export const registration_page_visited =
  new AlephBet.Goal("registration page visited")

export const registered_successfully =
  new AlephBet.Goal("registered successfully")

export const premium_subscription_started =
  new AlephBet.Goal("premium subscription started")

export const embedded_quiz_clicked =
  new AlephBet.Goal("Embedded quiz was clicked")

export const inline_quiz_finished =
  new AlephBet.Goal("3 questions complete inline quiz")

export const checkout_page_visited = new AlephBet.Goal("checkout page visited")

export const content_accessed = new AlephBet.Goal("content accessed")

export const content_accessed_five =
  new AlephBet.Goal("content accessed 5 or more times")

export const success_stories_page_visited =
  new AlephBet.Goal("success stories visited")

export const search_page_visited = new AlephBet.Goal("search page visited")

export const pricing_page_visited = new AlephBet.Goal("pricing page visited")

const pricing_page_visited_by_registered_user =
  new AlephBet.Goal("pricing page visited by registered user")

export const new_web_session =
  new AlephBet.Goal("web sessions", {unique: false})

export const payment_initiated = new AlephBet.Goal("payment initiated")

export const hosted_payment_initiated =
  new AlephBet.Goal("hosted payment initiated")

export const card_payment_initiated =
  new AlephBet.Goal("card payment initiated")

export const quiz_started = new AlephBet.Goal("quiz started")

export const quiz_started_non_unique =
  new AlephBet.Goal("quiz started non-unique", {unique: false})

export const custom_quiz_viewed = new AlephBet.Goal("custom quiz viewed")

export const custom_quiz_started = new AlephBet.Goal("custom quiz started")

export const video_played = new AlephBet.Goal("video played")

export const not_on_dashboard = new AlephBet.Goal("outside Dashboard")

export const setup_completed = new AlephBet.Goal("setup completed")

// when we run more than one experiment on the same page
// or even on different pages where we might want to check
// for interaction effects, then we want to create goals that
// show us not only their completion, but also which *other*
// experiment variant was picked.
//
// This method allows us to pass any number of experiments we
// want to tag this way
export const complete_cross_experiment_goal = (experiments, goal) => {
  for (const experiment of experiments) {
    // for every  *other* experiment in the list
    for (const other_experiment of experiments) {
      if (other_experiment.name !== experiment.name) {
        const other_name = other_experiment.name
        const other_variant = other_experiment.get_stored_variant()
        const goal_name = `${goal.name} [${other_name}=${other_variant}]`
        // we complete a new goal in the format:
        // "goal [other_experiment={variant}]"
        experiment.goal_complete(goal_name, goal.props)
      }
    }
  }
}

export function check_goals() {
  if (window.gon.registered_with_provider) registered_successfully.complete()

  signals.on("kenhub.payment_success", () => {
    premium_subscription_started.complete()
  })

  signals.on("kenhub.embedded_quiz_clicked", () => {
    embedded_quiz_clicked.complete()
  })

  signals.on("kenhub.inline_quiz_finished", () => {
    inline_quiz_finished.complete()
  })

  if (window.gon.just_subscribed) premium_subscription_started.complete()

  if (window.location.pathname.match(route_regex("/success-stories"))) {
    success_stories_page_visited.complete()
  }

  if (window.location.pathname.match(route_regex("/search"))) {
    search_page_visited.complete()
  }

  if (window.location.pathname.match(/pricing$/)) {
    pricing_page_visited.complete()
    if (logged_in_user()) pricing_page_visited_by_registered_user.complete()
  }

  if (window.location.pathname.match(/checkout$/)) {
    checkout_page_visited.complete()
  }

  if (
    window.location.pathname.match(
      route_regex("/(register|registration-ebook|registration-email)$")
    )
  ) {
    registration_page_visited.complete()
  }

  signals.on("kenhub.new_web_session", () => new_web_session.complete())

  signals.on("kenhub.payment_initiated", ({type}) => {
    if (type === "card") {
      card_payment_initiated.complete()
    } else {
      hosted_payment_initiated.complete()
    }
    payment_initiated.complete()
  })

  signals.on("kenhub.quiz_started", () => {
    quiz_started.complete()
    quiz_started_non_unique.complete()
    increment_content_accessed()
    content_accessed.complete()
  })

  signals.on("kenhub.video_played", () => {
    video_played.complete()
    increment_content_accessed()
    content_accessed.complete()
  })

  signals.on("kenhub.custom_quiz_started", () => custom_quiz_started.complete())

  if (
    ["article read", "atlas term viewed"].includes(
      window.gon.tracking != null ? window.gon.tracking.page_view : undefined
    )
  ) {
    increment_content_accessed()
    content_accessed.complete()
  }

  if (window.gon.tracking?.page_view === "custom quiz page viewed") {
    custom_quiz_viewed.complete()
  }

  if (content_accessed_count() >= 5) content_accessed_five.complete()

  if (!on_dashboard()) not_on_dashboard.complete()

  if (window.gon.current_user && window.gon.current_user.field_of_study) {
    return setup_completed.complete()
  }
}

const increment_content_accessed = () => {
  const settings = user_settings.get("experiments") || {}
  settings.content_accessed_count = content_accessed_count() + 1
  user_settings.register({experiments: settings})
}

const content_accessed_count = () => {
  const settings = user_settings.get("experiments") || {}
  return settings.content_accessed_count || 0
}
